<template xmlns:v-clipboard="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div :class="{ 'operator-mode': isDisable }" class="col-lg-12 d-flex justify-content-end mb-h">
      <WButton
        v-if="!isDisable"
        @click="openRuleWizard"
      >
        {{ $t('monitor.addRule') }}
      </WButton>
    </div>
    <div class="col-lg-12" :class="{ 'operator-mode': isDisable }">
      <div class="row">
        <div class="form-group col-lg-3 float-left">
          <div v-if="currentTab === 0" class="input-group input-group-sm">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              id="search"
              name="search"
              :placeholder="$t('general.searchPlaceHolder')"
              class="form-control"
              v-model="filters.query"
            />
          </div>
          <div v-if="currentTab === 1" class="input-group input-group-sm">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              id="searchForRules"
              name="search"
              v-model="filtersForRules.query"
              :placeholder="$t('general.searchPlaceHolder')"
              class="form-control"
              @input="inputInRulesSearchHandler"
            />
          </div>
        </div>
<!--        <div class="col-lg-8">-->
<!--          <button class="btn btn-primary btn-md float-right" @click="openRuleWizard" v-if="!isDisable">-->
<!--            {{ $t('monitor.addRule') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <div v-if="isFiltersActive" class="mb-1">
        <WButton secondary outline sm
          @click="resetAllFilters"
        >
          <i class="fa fa-close"></i>
          {{ $t('general.resetAllFilters') }}
        </WButton>
      </div>
      <ul class="nav nav-tabs row m-0 tabs-in-rules-page" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            :class="{ active: currentTab === 0 }"
            @click.prevent="currentTab = 0"
          >
            <i class="fa fa-align-justify"></i>
            {{ $t('general.cpes') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            :class="{ active: currentTab === 1 }"
            @click.prevent="currentTab = 1"
          >
            <i class="fa fa-align-justify"></i>
            {{ $t('general.rules') }}
          </a>
        </li>
      </ul>
      <div class="tab-content row m-0" :class="{'left-tab-selected-in-rules-page': currentTab === 0 }">
        <div class="tab-pane p-3 col-lg-12" id="tab1" role="tabpanel" :class="{ active: currentTab === 0 }">
          <table class="table loader-spinner-wrapper">
            <loader-spinner v-if="isShowingSpinnerOverTable"></loader-spinner>
            <thead>
              <tr>
                <th>{{ $t('general.name') }}</th>
                <th>{{ $t('general.model') }}</th>
                <th>{{ $t('monitor.rules') }}</th>
                <th v-if="!isDisable"></th>
                <!--<th>Actions</th>-->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cpe, index) in filterItems(cpesList)"
                :data-index="index"
                :data-id="cpe.id"
                @click="toggleEditRule"
                :key="cpe.id"
                class="cpe-row"
                v-if="cpesRulesList[cpe.id]"
              >
                <td class="responsive-row">
                  <span
                    class="badge-cpe badge-status ml-h"
                    :class="{
                      'badge-status__connected': cpe.connected,
                      'badge-status__disconnected': !cpe.connected
                    }"
                  >
                    <span v-if="cpe.name">{{ cpe.name }}</span>
                    <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                  </span>
                </td>
                <td>
                  <span class="text-s" v-tooltip.left="{ content: `<span class='small'>${cpe.model.name}</span>` }">
                    {{ cpe.model.name }}
                  </span>
                </td>
                <td>
                  <span
                    v-if="cpe.id != selectedCpe && cpesRulesList[cpe.id].rules.length > 0"
                    class="badge mr-q badge-success"
                    v-for="rule in cpesRulesList[cpe.id].rules"
                    :key="rule.id"
                  >
                    {{ rule.name }}
                  </span>
                  <span
                    class="badge mr-q badge-default"
                    v-if="cpesRulesList[cpe.id].rules.length === 0 && cpe.id != selectedCpe"
                  >
                    {{ $t('rules.noRules') }}
                  </span>
                  <multiselect
                    v-if="cpe.id === selectedCpe"
                    class="rule-multi"
                    v-model="cpesRulesList[cpe.id].rules"
                    :multiple="true"
                    :options="rulesList || []"
                    :closeOnSelect="true"
                    :hideSelected="true"
                    :max="3"
                    trackBy="id"
                    label="name"
                    @input="getRulesIdFromObject(cpe.id, cpesRulesList[cpe.id].rules)"
                    :selectLabel="$t('rules.addRule')"
                    deselectLabel="remove"
                    :placeholder="$t('portalStats.selectRules')"
                  ></multiselect>
                </td>
                <td v-if="!isDisable">
                  <i
                    v-if="cpe.id != selectedCpe && cpesRulesList[cpe.id].rules.length === 0"
                    class="rule-edit text-success fa fa-plus fa-lg mr-q"
                  ></i>
                  <i v-else-if="cpe.id != selectedCpe" class="rule-edit text-success fa fa-pencil fa-lg mr-q"></i>
                  <i v-else-if="cpe.id === selectedCpe" class="rule-save text-success fa fa-check fa-lg mr-q"></i>
                  <!-- <i class="cpe-action text-muted fa fa-refresh fa-lg mr-q"></i> -->
                </td>
              </tr>
              <tr v-if="filterItems(cpesList).length === 0">
                <td colspan="20" class="no-data-row">
                  <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane p-3 col-lg-12" id="tab2" role="tabpanel" :class="{ active: currentTab === 1 }">
          <table class="table loader-spinner-wrapper">
            <loader-spinner v-if="isShowingSpinnerOverTable"></loader-spinner>
            <thead>
              <tr>
                <th>{{ $t('general.name') }}</th>
                <th>{{ $t('general.type') }}</th>
                <th>{{ $t('monitor.data') }}</th>
                <th>{{ $t('rules.notificationTypeCaption') }}</th>
                <!--                <th>{{ $t('rules.triggerScript') }}</th>-->
                <th>{{ $t('rules.notifySettingsForTableCaption') }}</th>
                <th v-if="!isDisable"></th>
              </tr>
            </thead>
            <tbody
              v-for="(rule, index) in filterItemRules(rulesList)"
              :data-index="index"
              :data-id="rule.id"
              :key="rule.id"
              @click="toggleEditRule"
            >
              <tr
                :class="{
                  'rules_show_more_in_rules_table__show rules_show_more_in_rules_table__show--title':
                    showedRulesMore[rule.id]
                }"
                class="cpe-row default-cursor-in-rules-table"
              >
                <td class="responsive-row">{{ rule.name }}</td>
                <!--                <td class="">{{ ruleService.ruleTypes[rule.type] }}</td>-->
                <td class="">{{ $t(`rules.ruleTypes.${rule.type}`) }}</td>
                <!-- <td class="responsive-row"> {{ rule.id }}</td> -->
                <td v-if="rule.type !== 'customer_activity'">
                  <span v-if="rule.data" class="rule-range">{{ rule.data.lower }} ; {{ rule.data.upper }}</span>
                  <span class="texticon--muted" v-else>{{ $t('misc.no_data') }}</span>
                </td>
                <td v-if="rule.type === 'customer_activity'">
                  <span v-if="getLowerValueForCustomerActivityRuleForTableColumn(rule) !== ''" class="rule-range">
                    {{ getLowerValueForCustomerActivityRuleForTableColumn(rule) }}
                    {{ $t('rules.minTrafficThresholdCaptionBytesForTableColumn') }}
                  </span>
                  <span class="texticon--muted" v-else>{{ $t('misc.no_data') }}</span>
                </td>
                <td>
                  <span class="rule-range">{{ getNotifyTypeCaptionWithI18n(rule.notify_type) }}</span>
                </td>
                <td v-if="rule.notify_type === ''">
                  <span
                    v-tooltip.top-center="{
                      content: `<div class='small'>${rule.post_script ? rule.post_script : $t('general.no')}</div>`
                    }"
                  >
                    <span class="tooltip-text">{{ $t('rules.triggerScript') }}</span>
                    <small>
                      <i
                        class="fa fa-clipboard icon-clipboard"
                        @click.stop
                        v-clipboard:copy="rule.post_script"
                        v-clipboard:success="onCopyClipboard"
                        v-clipboard:error="onErrorClipboard"
                      ></i>
                    </small>
                  </span>
                </td>
                <td v-else-if="rule.notify_type === 'telegram'">
                  <div>
                    <span>{{ $t('rules.notifySettingsForTableForTelegramToChatId') }}:</span>
                    <span class="rules-table-action-params-to-in-column">{{ rule.notify_settings.chat_id }}</span>
                  </div>
                  <div>
                    <small
                      v-if="!showedRulesMore[rule.id]"
                      @click.stop.prevent="showRuleMore(rule)"
                      class="tooltip-text rules-table-show-more-show-caption"
                    >
                      {{ $t('rules.notifySettingsForTableShowMore') }}
                    </small>
                    <small
                      v-if="showedRulesMore[rule.id]"
                      @click.stop.prevent="showRuleMore(rule)"
                      class="tooltip-text rules-table-show-more-show-caption"
                    >
                      {{ $t('rules.notifySettingsForTableHide') }}
                    </small>
                  </div>
                </td>
                <td v-else-if="rule.notify_type === 'email'">
                  <div>
                    <span>{{ $t('rules.notifySettingsForTableForEmailToAddress') }}:</span>
                    <span class="rules-table-action-params-to-in-column">{{ rule.notify_settings.to }}</span>
                  </div>
                  <div>
                    <small
                      v-if="!showedRulesMore[rule.id]"
                      @click.stop.prevent="showRuleMore(rule)"
                      class="tooltip-text rules-table-show-more-show-caption"
                    >
                      {{ $t('rules.notifySettingsForTableShowMore') }}
                    </small>
                    <small
                      v-if="showedRulesMore[rule.id]"
                      @click.stop.prevent="showRuleMore(rule)"
                      class="tooltip-text rules-table-show-more-show-caption"
                    >
                      {{ $t('rules.notifySettingsForTableHide') }}
                    </small>
                  </div>
                </td>
                <td v-else-if="rule.notify_type === 'mqtt'">
                  <div>
                    <span>{{ $t('rules.notifySettingsForTableForMQTTToTopic') }}:</span>
                    <span class="rules-table-action-params-to-in-column">{{ rule.notify_settings.topic }}</span>
                  </div>
                  <div>
                    <small
                      v-if="!showedRulesMore[rule.id]"
                      @click.stop.prevent="showRuleMore(rule)"
                      class="tooltip-text rules-table-show-more-show-caption"
                    >
                      {{ $t('rules.notifySettingsForTableShowMore') }}
                    </small>
                    <small
                      v-if="showedRulesMore[rule.id]"
                      @click.stop.prevent="showRuleMore(rule)"
                      class="tooltip-text rules-table-show-more-show-caption"
                    >
                      {{ $t('rules.notifySettingsForTableHide') }}
                    </small>
                  </div>
                </td>
                <td v-else></td>
                <td v-if="!isDisable">
                  <i class="rule-delete text-danger fa fa-remove fa-lg mr-q pointer-cursor-in-rules-table"></i>
                  <!-- <i class="cpe-action text-muted fa fa-refresh fa-lg mr-q"></i> -->
                </td>
              </tr>
              <transition name="data-transition-in-rules-table">
                <tr
                  v-if="showedRulesMore[rule.id]"
                  class="rules_show_more_in_rules_table__show default-cursor-in-rules-table"
                >
                  <!--                <td class="rules_show_more_in_rules_table__data"></td>-->
                  <!--                <td class="rules_show_more_in_rules_table__data"></td>-->
                  <!--                <td class="rules_show_more_in_rules_table__data"></td>-->
                  <!--                <td class="rules_show_more_in_rules_table__data"></td>-->
                  <td colspan="6" class="rules_show_more_in_rules_table__data">
                    <div>
                      <div class="mb-1">
                        <h6>
                          {{ $t('rules.notificationTypeCaption') }}:
                          {{ getNotifyTypeCaptionWithI18n(rule.notify_type) }}
                        </h6>
                      </div>
                    </div>
                    <div v-if="rule.notify_type === 'telegram'">
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsTelegramTokenCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.token }}&nbsp;</span>
                        <info class="" :content="$t('rules.notifySettingsTelegramTokenInfo')" />
                      </div>
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsTelegramChatIdCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.chat_id }}&nbsp;</span>
                        <info class="" :content="$t('rules.notifySettingsTelegramChatIdInfo')" />
                      </div>
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsTelegramTextCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.text }}</span>
                      </div>
                    </div>
                    <div v-if="rule.notify_type === 'email'">
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsEmailToCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.to }}</span>
                      </div>
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsEmailSubjectCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.subject }}</span>
                      </div>
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsEmailTextCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.text }}</span>
                      </div>
                    </div>
                    <div v-if="rule.notify_type === 'mqtt'">
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsMQTTTopicCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.topic }}</span>
                        <info class="" :content="$t('rules.notifySettingsMQTTTopicInfo')" />
                      </div>
                      <div class="mb-q">
                        <strong>{{ $t('rules.notifySettingsMQTTTextCaption') }}:&nbsp;</strong>
                        <span>{{ rule.notify_settings.text }}</span>
                      </div>
                    </div>
                    <div>
                      <small class="tooltip-text" @click.stop.prevent="showRuleMore(rule)">
                        {{ $t('rules.notifySettingsForTableHide') }}
                      </small>
                    </div>
                  </td>
                </tr>
              </transition>
            </tbody>
            <tr v-if="filterItemRules(rulesList).length === 0">
              <td colspan="20" class="no-data-row default-cursor-in-rules-table">
                <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <modal
        title="Delete Rule"
        class="modal-danger"
        v-model="deletingRuleModal"
        okText="Confirm"
        cancelText="Cancel"
        @ok="deleteRule"
        @cancel="cancelDeleting"
        effect="fade/zoom"
      >
        <h4 slot="modal-title" class="modal-title">{{ $t('monitor.ruleDelete') }}</h4>
        <p v-if="ruleIdToDelete">
          {{ $t('monitor.ruleConfirmDeleting') }}
          <strong>
            {{
              commonService.ruleNamebyId(ruleIdToDelete)
                ? commonService.ruleNamebyId(ruleIdToDelete).name
                : ruleIdToDelete
            }}
          </strong>
        </p>
        <div slot="modal-footer" class="modal-footer">
          <WButton secondary
            @click="deletingRuleModal = false"
          >
            {{ $t('general.cancel') }}
          </WButton>
          <WButton danger outline
            @click="deleteRule"
          >
            {{ $t('general.delete') }}
          </WButton>
        </div>
      </modal>
    </div>
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import Modal from '../../components/Modal.vue';
import ruleService from '../../services/ruleService';
import cpeService from '../../services/cpeService';
import commonService from '../../services/commonService';
import Filters from '../../filters';
import Info from '../../components/Universal/info-icon.vue';

import { intervalUpdateModeMixin } from '@/mixins';

export default {
  name: 'Rules',
  components: { Multiselect, Modal, Info },
  mixins: [intervalUpdateModeMixin],
  data() {
    return {
      showedRulesMore: {},
      filters: {
        query: '',
        interfacesFilter: null,
        connectedFilter: null
      },
      filtersForRules: {
        query: ''
      },
      inputInRulesSerchTimeout: null,
      deletingRuleModal: false,
      ruleList: [],
      cpeRules: [],
      tempCpeRules: [],
      selectedCpe: '',
      ruleIdToDelete: '',
      currentTab: 0
    };
  },
  computed: {
    // filterItemRules() {
    //   let filteredRules = JSON.parse(JSON.stringify(this.rulesList));
    //
    //   filteredRules = this.rulesSearchQuery(filteredRules, this.filtersForRules.query);
    //   // filteredCpes = Filters.cpeInterfaceCountFilter(filteredCpes, this.filters.interfacesFilter);
    //   // filteredCpes = Filters.cpeConnectionFilter(filteredCpes, this.filters.connectedFilter);
    //   return filteredRules;
    // },
    commonService() {
      return commonService;
    },
    ruleService() {
      return ruleService;
    },
    isFiltersActive() {
      if (this.currentTab === 0) {
        return this.filters.query !== '' ||
          this.filters.interfacesFilter !== null ||
          this.filters.connectedFilter !== null

      } else if (this.currentTab === 1) {
        return this.filtersForRules.query !== ''
      }
      return false
    },
    isRulesLoading() {
      return this.$store.state.loadingRules;
    },
    isCpesRulesLoading() {
      return this.$store.state.loadingCpesRules;
    },
    isCpesLoading() {
      return this.$store.state.loadingCpes;
    },
    isShowingSpinnerOverTable() {
      return this.isRulesLoading || this.isCpesRulesLoading || this.isCpesLoading;
    },
    cpesList: {
      get() {
        return this.$store.state.cpesList;
      },
      set() {}
    },
    rulesList() {
      return this.$store.state.rulesList;
    },
    cpesRulesList: {
      get() {
        const rulesList = JSON.parse(JSON.stringify(this.$store.state.cpesRulesList));
        for (const cpeRuleObject in rulesList) {
          const newRulesList = [];
          rulesList[cpeRuleObject].rules.forEach((ruleId) => {
            this.ruleList.forEach((ruleObject) => {
              if (ruleObject.id == ruleId) {
                newRulesList.push(ruleObject);
              }
            });
          });
          rulesList[cpeRuleObject].rules = newRulesList;
        }
        return rulesList;
      },
      set() {}
    },

    isDisable() {
      return this.$store.state.userData.role == 'operator';
    }
  },
  methods: {
    showRuleMore(rule) {
      if (!this.showedRulesMore[rule.id]) {
        this.$set(this.showedRulesMore, rule.id, true);
      } else {
        this.$set(this.showedRulesMore, rule.id, false);
      }
    },
    resetAllFilters() {
      if (this.currentTab === 0) {
        this.filters.query = '';
        this.filters.interfacesFilter = null;
        this.filters.connectedFilter = null;
      } else if (this.currentTab === 1) {
        this.filtersForRules.query = '';
      }
    },
    getNotifyTypeCaptionWithI18n(notifyYype) {
      if (notifyYype === 'telegram') {
        return this.$t('rules.notificationTypes.telegram');
      } if (notifyYype === 'email') {
        return this.$t('rules.notificationTypes.email');
      } if (notifyYype === 'mqtt') {
        return this.$t('rules.notificationTypes.mqtt');
      } if (notifyYype === '') {
        return this.$t('rules.script');
      }
      return notifyYype;
    },
    clearRuleSearchFilter() {
      this.filtersForRules.query = '';
    },
    inputInRulesSearchHandler(event) {
      // console.log(event.target.value);
      clearTimeout(this.inputInRulesSerchTimeout);
      this.inputInRulesSerchTimeout = setTimeout(() => {
        this.showedRulesMore = {};
        this.filtersForRules.query = event.target.value;
      }, 300);
    },
    rulesSearchQuery(rulesArray, searchQuery) {
      if (!rulesArray) {
        return [];
      }
      return rulesArray.filter((item) => {
        if (item.name) {
          return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        }
        if (searchQuery) {
          return null;
        }
        return item;
      });
    },
    getLowerValueForCustomerActivityRuleForTableColumn(ruleItem) {
      try {
        const rule = ruleItem;
        if (
          Object.prototype.hasOwnProperty.call(rule, 'data') &&
          typeof rule.data === 'object' &&
          Object.prototype.hasOwnProperty.call(rule.data, 'lower') &&
          rule.data.lower !== ''
        ) {
          return rule.data.lower;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    onCopyClipboard() {
      VueNotifications.success({ message: this.$t('monitor.notificationRuleCopyingScriptWasSuccessful') });
    },
    onErrorClipboard() {
      VueNotifications.error({ message: this.$t('monitor.notificationRuleCopyingScriptWasUnsuccessful') });
    },
    toggleEditRule(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { id } = selectedNode.dataset;
      this.selectedCpe = id;

      if (e.target.classList.contains('rule-delete')) {
        this.openDeletingDialog(id);
        return;
      }
      if (e.target.classList.contains('rule-save')) {
        this.selectedCpe = '';
      }
    },
    openRuleWizard() {
      this.$router.push({ name: 'rule-wizard' });
    },
    openDeletingDialog(id) {
      this.deletingRuleModal = true;
      this.ruleIdToDelete = id;
    },
    deleteRule() {
      this.deletingRuleModal = false;
      ruleService.deleteRule(this);
      this.ruleIdToDelete = '';
    },
    cancelDeleting() {
      this.ruleIdToDelete = '';
    },
    refreshRulesList() {
      ruleService.getRules(this);
    },
    refreshCpeRuleList() {
      ruleService.refreshCpesRules(this);
    },
    filterItems(items) {
      let filteredCpes = items;

      filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
      // filteredCpes = Filters.cpeInterfaceCountFilter(filteredCpes, this.filters.interfacesFilter);
      // filteredCpes = Filters.cpeConnectionFilter(filteredCpes, this.filters.connectedFilter);
      return filteredCpes;
    },
    filterItemRules(rulesItems) {
      let filteredRules = JSON.parse(JSON.stringify(rulesItems));
      filteredRules = this.rulesSearchQuery(filteredRules, this.filtersForRules.query);
      return filteredRules;
    },
    setFilter(filterName, value) {
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    getRulesIdFromObject(cpeId, rulesList) {
      this.tempCpeRules.push({
        cpeId,
        rules: rulesList.map((ruleObject) => ruleObject.id)
      });
      // let cpeRuleQuery = this.tempCpeRules;
      ruleService.createCpeRules(this);
    }
  },
  watch: {
    currentTab() {
      this.clearRuleSearchFilter();
    }
  },
  created() {
    ruleService.getRules(this);
    ruleService.getCpesRules(this);
    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithDataRemaining(this);
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      this.refreshRulesList();
      this.refreshCpeRuleList();

      // ruleService.getRules(this);
      // ruleService.getCpesRules(this);
      // cpeService.getAllCompactCpesWithDataRemaining(this);
    });
  }
};
</script>

<style lang="scss">
.rule-edit,
.rule-delete,
.rule-save {
  transition: transform 0.2s;
}
.rule-edit:hover,
.rule-delete:hover,
.rule-save:hover {
  transform: scale(1.2);
}
.rule-range {
  white-space: nowrap;
}

.data-transition-in-rules-table {
  &-enter-active {
    transition: all 0.3s;
  }
  &--leave-active {
    transition: all 0.3s;
  }
  &-enter,
  &--leave-to {
    opacity: 0;
    transform: translateX(-40px);
  }
}
</style>

<style scoped>
.loader-spinner-wrapper {
  position: relative;
}
.rules-table-action-params-to-in-column {
  font-weight: 600;
  margin-left: 0.25em;
}
.rules-table-show-more-show-caption {
  cursor: pointer !important;
}
.default-cursor-in-rules-table {
  cursor: default !important;
}
.pointer-cursor-in-rules-table {
  cursor: pointer !important;
}
</style>
<style scoped lang="scss">
.rules_show_more_in_rules_table {
  min-width: 900px;

  &__type {
    /*text-transform: capitalize;*/
  }

  &__show-action {
    font-weight: bolder;
    cursor: pointer;
  }

  &__show {
    background: rgba(236, 236, 236, 0.3);
    &--title {
      background: rgba(236, 236, 236, 0.49);
    }
  }

  &__data {
    border-top: 0px !important;
  }

  &__info {
    display: flex;
  }

  &__data-list {
    font-size: 90%;
    list-style: none;

    & li {
      word-break: break-word;
    }
  }
  &__data-item {
    font-weight: bold;
  }

  &__icon {
    transition: 0.3s;
    &--show {
      transform: rotate(-90deg);
    }
  }

  &__monitor-icon {
    height: 15px;
  }
}
</style>
